
import {defineComponent, ref, computed, watch} from "vue";
import itemImage from "@/components/dashboard/itemImage.vue";
import * as toastr from "toastr";
import FuncUtils from "@/utils/funcUtils";
import {useLoading} from "vue-loading-overlay";
import {useStore} from "vuex";
import {key} from "@/store/index";
import {useRouter, useRoute} from "vue-router";
import Product from "@/models/product";
import StorageService from "@/services/StorageService";

export default defineComponent({
  setup(){
    const product = ref({} as Product);
    const imageFiles = ref([]);
    const newImageUrls = ref([] as string[]);
    const addProductRef = ref(null);
    const $loader:any = useLoading();
    const store = useStore(key);
    store.dispatch('category/fetch');
    store.dispatch('category/fetchSub');
    store.dispatch('region/fetch');
    store.dispatch('region/fetchSub');
    store.dispatch('plan/fetch');
    const router = useRouter();
    const route = useRoute();
    const tempImageURLs = ref([] as string[]);

    const regions = computed(() => store.getters['region/getRegions'])
    const plans = computed(() => store.getters['plan/plans'])
    const subRegions = computed(()=> store.getters['region/getSubRegions'].filter(subRegion=>subRegion.regionID===product.value.region))
    const categories = computed(() => store.getters['category/getCategories'])
    const subCategories = computed(() => store.getters['category/getSubCategories'].filter(subCategory => subCategory.categoryID === product.value.category))


    const fetchProduct = async ()=>{
    const loader = $loader.show({container: addProductRef.value});
    //  product.value = {} as Product;
      const _product = await store.dispatch('product/get', {id: route.params.id});
      loader.hide();
      if(_product){
        product.value = _product;
      }
    }
    fetchProduct();

    const updateProduct = async ()=>{
      if(FuncUtils.validateFields(product.value, ['id', 'viewedOwnerPhoneNumber','viewedBy','createdAt','updatedAt'])){
      const loader =  $loader.show({container: addProductRef.value});
        product.value.images.concat(...newImageUrls.value);
        await store.dispatch('product/update',{product: product.value});
        if(!store.getters['product/getError']){
          if(tempImageURLs.value.length > 0){
            try{
              const promises = tempImageURLs.value.map(url=>new StorageService().deleteFile(url))
              await Promise.all(promises);
            }catch (e){
              console.log("unable to delete product images:", e)
            }
          }
          toastr.success(store.getters['product/getMessage']);
          await router.push({name: 'Products'})
        }else{
          // remove new images previously added
          product.value.images = product.value.images.filter(url=>!newImageUrls.value.includes(url));
          toastr.error(store.getters['product/getMessage'])
        }
        loader.hide();
      }else{
        toastr.warning("All required fields need to be filled")
      }

    }
    const addImageForDeletion = (url: string, idx: number)=>{
      tempImageURLs.value.push(url);
      product.value.images.splice(idx, 1);
    }

    watch(() =>route, () => fetchProduct() );


    return {
      product,
      imageFiles,
      newImageUrls,
      updateProduct,
      addImageForDeletion,
      regions,
      subRegions,
      categories,
      subCategories,
      plans,
      addProductRef
    }
  },
  components: {
    itemImage
  }
})
